import {
    createRouter,
    createWebHistory
} from 'vue-router'
import store from '../store';
import { setTitle } from '@/utils/Common';

const routes = [{
        path: '/',
        redirect: {name: 'home'}
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/Login'),
        meta: {
            title: '登录'
        }
    },
    {
        path: '/home',
        name: 'home',
        component: () => import('../views/Home'),
        meta: {
            login: true
        }
    },
    {
        path: '/everyDayPractice',
        name: 'everyDayPractice',
        component: () => import('../views/Home/Practice/EveryDayPractice'),
        meta: {
            login: true,
            title: '每日一练'
        }
    },
    // {
    //     path: '/examDetail',
    //     name: 'examDetail',
    //     component: () => import('../views/Home/Me/ExamDetail'),
    //     meta: {
    //         login: true,
    //         title: '本月考试成绩'
    //     }
    // },
        {
        path: '/score',
        name: 'score',
        component: () => import('../views/Home/Me/ExamDetail'),
        meta: {
            login: true,
            title: '本月考试成绩'
        }
    },
    {
        path: '/scoreDetail',
        name: 'scoreDetail',
        component: () => import('../views/Home/Me/ScoreDetail'),
        meta: {
            login: true,
            title: '积分排行'
        }
    },
    {
        path: '/editPassword',
        name: 'editPassword',
        component: () => import('../views/Home/Me/EditPassword'),
        meta: {
            login: true,
            title: '修改密码'
        }
    },
    {
        path: '/selfTraining',
        name: 'selfTraining',
        component: () => import('../views/Home/Practice/SelfTraining'),
        meta: {
            login: true,
            title: '自练自查'
        }
    },
    // {
    //     path: '/examScene',
    //     name: 'examScene',
    //     component: () => import('../views/Home/Exam/ExamScene'),
    //     meta: {
    //         login: true,
    //         title: '考试'
    //     }
    // },
    {
        path: '/scene',
        name: 'scene',
        component: () => import('../views/Home/Exam/ExamScene'),
        meta: {
            login: true,
            title: '考试'
        }
    },
    {
        path: '/setting',
        name: 'setting',
        component: () => import('../views/Home/Me/Setting'),
        meta: {
            login: true,
            title: '设置'
        }
    },
    {
        path: '/vedioDetails',
        name: 'vedioDetails',
        component: () => import('../views/Home/Study/vedioDetails'),
        meta: {
            login: true,
            title: '视频详情'
        }
    },
    {
        path: '/fileDetails',
        name: 'fileDetails',
        component: () => import('../views/Home/Study/fileDetails'),
        meta: {
            login: true,
            title: '文章详情'
        }
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to,from,next) => {
    store.commit('setRouteName',to.name);

    if(to.meta.title)setTitle(to.meta.title);

    if(to.meta.login)
    {
        if(store.getters['user/isLogin']())
        {
            next();
        }
        else
        {
            next({
                path: '/login'
            });
        }
    }
    else
    {
        next();
    }
});

router.afterEach((to,from,next) => {
    scrollTo(0,0);
});

export default router