import { createStore } from 'vuex';
import home from './home';
import user from './user';
import practice from './practice';
import exam from './exam';
import fileDetails from './fileDetails';
import vedioDetails from './vedioDetails'

export default createStore({
  state: {
    routeName: ''
  },
  mutations: {
    setRouteName(state,value)
    {
      state.routeName = value;
    }
  },
  modules: {
    home,
    user,
    practice,
    exam,
    fileDetails,
    vedioDetails
  }
});