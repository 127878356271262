export default {
    namespaced: true,
    state: {
        selectedFile: null
    },
    mutations: {
        setSelectedFile(state, file) {
            state.selectedFile = file;
        }
    },
    actions: {
        setSelectedFile({ commit }, file) {
            commit('setSelectedFile', file);
        }
    },
    getters: {
        selectedFile: state => state.selectedFile
    }
}