export default {
    namespaced: true,
    state: {
        selectedVideo: null
    },
    mutations: {
        setSelectedVideo(state, video) {
            state.selectedVideo = video;
        }
    },
    actions: {
        setSelectedVideo({ commit }, video) {
            commit('setSelectedVideo', video);
        }
    },
    getters: {
        selectedVideo: state => state.selectedVideo
    }
}