import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';
import ClientPlugin from './plugins/ClientPlugin';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

const app = createApp(App);

app.use(store);
app.use(router);
app.use(Antd);
app.use(ClientPlugin);
// 注册 FontAwesome 图标
library.add(faPlay);

// 注册 FontAwesome 组件
app.component('font-awesome-icon', FontAwesomeIcon);
app.mount('#app')
