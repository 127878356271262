export default {
    namespaced: true,
    state: {
        tabs: [
            {label: '学习',icon: 'icon-score'},
            {label: '练习',icon: 'icon-practice'},
            {label: '考试',icon: 'icon-exam'},
            {label: '我的',icon: 'icon-user'}
        ],
        selectedTabIdx: 0
    },
    mutations: {
        setSelectedTabIdx(state,value)
        {
            state.selectedTabIdx = value;
        }
    }
};